@font-face {
  font-family: "Avenir";
  src: url("../Fonts/Avenir/Avenir-Medium.eot");
  src: url("../Fonts/Avenir/Avenir-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../Fonts/Avenir/Avenir-Medium.woff2") format("woff2"),
    url("../Fonts/Avenir/Avenir-Medium.woff") format("woff"),
    url("../Fonts/Avenir/Avenir-Medium.ttf") format("truetype"),
    url("../Fonts/Avenir/Avenir-Medium.svg#Avenir-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("../Fonts/Avenir/Avenir-Black.eot");
  src: url("../Fonts/Avenir/Avenir-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../Fonts/Avenir/Avenir-Black.woff2") format("woff2"),
    url("../Fonts/Avenir/Avenir-Black.woff") format("woff"),
    url("../Fonts/Avenir/Avenir-Black.ttf") format("truetype"),
    url("../Fonts/Avenir/Avenir-Black.svg#Avenir-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Book";
  src: url("../Fonts/Avenir/Avenir-Book.eot");
  src: url("../Fonts/Avenir/Avenir-Book.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/Avenir/Avenir-Book.woff2") format("woff2"),
    url("../Fonts/Avenir/Avenir-Book.woff") format("woff"),
    url("../Fonts/Avenir/Avenir-Book.ttf") format("truetype"),
    url("../Fonts/Avenir/Avenir-Book.svg#Avenir-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../Fonts/Gilroy-light.woff2") format("woff2"),
    url("../Fonts/Gilroy-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Questrial";
  src: url("../Fonts/Questrial/Questrial-regular-webfont.woff2") format("woff2"),
    url("../Fonts/Questrial/Questrial-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-size: 0.875rem;
  background-color: #f8f8f8;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.breadcrumb-item.active {
  display: flex;
  color: #02ca69;
  width: inherit;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.wrapper {
  overflow-y: hidden;
}

.ico {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
}

.alerts {
  background-color: #de0000;
  vertical-align: middle;
  height: 26px;
  width: 26px;
  border-radius: 50px;
  color: white;
  text-align: center;
  font-size: 16px;
  margin-bottom: 3px;
  padding-top: 1px;
}

.alerts a {
  color: white;
}

.alerts-cards {
  position: relative;
  top: -55px;
  height: 35px;
  width: 35px;
  font-size: 19px;
  padding-top: 4px;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  align-self: flex-end !important;
}

.ico-container {
  align-items: center;
  display: flex;
  height: 58px;
  justify-content: center;
  width: 58px;
}

.ico--building {
  background-image: url("../Img/icons/office.svg");
  min-height: 20px;
  min-width: 20px;
  margin-left: 6px;
}

.ico--building--active {
  background-image: url("../Img/icons/office-active.svg");
  min-height: 20px;
  min-width: 20px;
}

.ico.ico--warm {
  background-image: url("../Img/icons/velocimetro-red.svg");
  min-height: 30px;
  min-width: 40px;
}

.ico.ico--cool {
  background-image: url("../Img/icons/velocimetro-blue.svg");
  min-height: 30px;
  min-width: 40px;
}

.ico.ico--gray {
  background-image: url("../Img/icons/velocimetro-gray.svg");
  min-height: 30px;
  min-width: 40px;
}

.ico.ico--clock {
  background-image: url("../Img/icons/clock.svg");
  min-height: 30px;
  min-width: 30px;
}

.ico.ico--thermometer {
  background-image: url("../Img/icons/termometro.svg");
  min-height: 30px;
  min-width: 30px;
}

.ico.ico--drop {
  background-image: url("../Img/icons/soltar.svg");
  min-height: 30px;
  min-width: 30px;
}

.ico.ico--ventilation--on {
  background-image: url("../Img/icons/ventilation-blue.svg");
  min-height: 65px;
  min-width: 58px;
  margin-top: -15px;
}

.ico.ico--ventilation--off {
  background-image: url("../Img/icons/ventilation-gray.svg");
  min-height: 65px;
  min-width: 58px;
  margin-top: -15px;
}

.ico.ico--dashboard {
  background-image: url("../Img/icons/dashboard.svg");
  min-height: 20px;
  min-width: 20px;
  background-size: contain;
}

.ico.ico--whatsapp {
  background-image: url("../Img/icons/whatsapp.svg");
  min-height: 1.5rem;
  min-width: 1.55rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--webpush_browser {
  background-image: url("../Img/icons/webpush_browser.svg");
  min-height: 1.8rem;
  min-width: 1.9rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--envelope-regular {
  background-image: url("../Img/icons/envelope-regular.svg");
  min-height: 1.5rem;
  min-width: 1.9rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--power-off-solid {
  background-image: url("../Img/icons/power-off-solid.svg");
  min-height: 1.8rem;
  min-width: 1.85rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--plus-solid {
  background-image: url("../Img/icons/plus-solid.svg");
  min-height: 0.95rem;
  min-width: 0.9rem;
}

.ico.ico--folder-solid {
  background-image: url("../Img/icons/folder-solid.svg");
  min-height: 1.1rem;
  min-width: 1.4rem;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(82deg) brightness(105%)
    contrast(105%);
}

.ico.ico--qrcode-solid {
  background-image: url("../Img/icons/qr-code.svg");
  min-height: 1.3rem;
  min-width: 1.3rem;
}

.ico.ico--thumbs-up {
  background-image: url("../Img/icons/thumbs-up-solid.svg");
  min-height: 1.3rem;
  min-width: 1.2rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--bell-regular {
  background-image: url("../Img/icons/bell-regular.svg");
  min-width: 1.1rem;
  min-height: 1.3rem;
}

.ico.ico--money {
  background-image: url("../Img/icons/money.svg");
  min-height: 30px;
  min-width: 30px;
}

.ico.ico--plugging_electricity {
  background-image: url("../Img/icons/plugging_electricity.svg");
  min-height: 30px;
  min-width: 30px;
}

.ico.ico--lightning_bolt {
  background-image: url("../Img/icons/lightning_bolt.svg");
  min-height: 30px;
  min-width: 30px;
}

.ico.ico--sort {
  background-image: url("../Img/icons/sort.svg");
  min-height: 20px;
  min-width: 20px;
  vertical-align: text-bottom;
  margin-left: 2rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--sort-solid {
  background-image: url("../Img/icons/sort-solid.svg");
  min-height: 20px;
  min-width: 13px;
  vertical-align: text-bottom;
  margin-left: 2rem;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--sort-ascending {
  background-image: url("../Img/icons/sort-up-solid.svg");
  min-height: 20px;
  min-width: 13px;
  align-self: center;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--sort-descending {
  background-image: url("../Img/icons/sort-down-solid.svg");
  min-height: 20px;
  min-width: 13px;
  align-self: center;
  filter: invert(63%) sepia(0%) saturate(3077%) hue-rotate(67deg)
    brightness(85%) contrast(99%);
}

.ico.ico--es {
  background-image: url("../Img/icons/es.svg");
  min-height: 20px;
  min-width: 30px;
  align-self: center;
}

.ico.ico--ca {
  background-image: url("../Img/icons/ca.svg");
  min-height: 20px;
  min-width: 30px;
  align-self: center;
}

.ico.ico--gb {
  background-image: url("../Img/icons/gb.svg");
  min-height: 20px;
  min-width: 40px;
  align-self: center;
  transform: scale(0.75, 1);
}

.ico.ico--download {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 477.867 477.867' style='enable-background:new 0 0 477.867 477.867;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M443.733,307.2c-9.426,0-17.067,7.641-17.067,17.067v102.4c0,9.426-7.641,17.067-17.067,17.067H68.267 c-9.426,0-17.067-7.641-17.067-17.067v-102.4c0-9.426-7.641-17.067-17.067-17.067s-17.067,7.641-17.067,17.067v102.4 c0,28.277,22.923,51.2,51.2,51.2H409.6c28.277,0,51.2-22.923,51.2-51.2v-102.4C460.8,314.841,453.159,307.2,443.733,307.2z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3Cg%3E%3Cpath d='M335.947,295.134c-6.614-6.387-17.099-6.387-23.712,0L256,351.334V17.067C256,7.641,248.359,0,238.933,0 s-17.067,7.641-17.067,17.067v334.268l-56.201-56.201c-6.78-6.548-17.584-6.36-24.132,0.419c-6.388,6.614-6.388,17.099,0,23.713 l85.333,85.333c6.657,6.673,17.463,6.687,24.136,0.031c0.01-0.01,0.02-0.02,0.031-0.031l85.333-85.333 C342.915,312.486,342.727,301.682,335.947,295.134z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
  height: 17px;
  width: 17px;
  margin-left: 2.5px;
}

.ico.ico--arrow-left-solid {
  background-image: url("../Img/icons/arrow-left-solid.svg");
  min-height: 17px;
  min-width: 16px;
  align-self: center;
}

.ico.ico--arrow-right-solid {
  background-image: url("../Img/icons/arrow-right-solid.svg");
  min-height: 17px;
  min-width: 16px;
  align-self: center;
}

.ico.ico--file-csv-solid {
  background-image: url("../Img/icons/file-csv-solid.svg");
  min-height: 1.3rem;
  min-width: 1.3rem;
  align-self: center;
}

.ico.ico--file-pdf-solid {
  background-image: url("../Img/icons/file-pdf-solid.svg");
  min-height: 1.3rem;
  min-width: 1.3rem;
  align-self: center;
}

.ico.ico--person-empty {
  background-image: url("../Img/icons/person-empty.svg");
  min-height: 48px;
  min-width: 58px;
  align-self: center;
  filter: invert(19%) sepia(0%) saturate(0%) hue-rotate(144deg) brightness(95%)
    contrast(89%);
}

.ico.ico--person-fill {
  background-image: url("../Img/icons/person-fill.svg");
  min-height: 48px;
  min-width: 58px;
  align-self: center;
  filter: invert(19%) sepia(0%) saturate(0%) hue-rotate(144deg) brightness(95%)
    contrast(89%);
}

.ico.ico--two-person-fill {
  background-image: url("../Img/icons/two-person-fill.svg");
  min-height: 48px;
  min-width: 58px;
  align-self: center;
  filter: invert(19%) sepia(0%) saturate(0%) hue-rotate(144deg) brightness(95%)
    contrast(89%);
}

.ico.ico--group-people-fill {
  background-image: url("../Img/icons/group-people-fill.svg");
  min-height: 48px;
  min-width: 58px;
  align-self: center;
  filter: invert(19%) sepia(0%) saturate(0%) hue-rotate(144deg) brightness(95%)
    contrast(89%);
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 60px 0 0;
  /* Height of navbar */
  width: 260px;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
  background-color: #ffffff;
  transform: translateX(-260px);
  transition: transform 0.2s;
}

@media (min-width: 1367px) {
  .sidebar {
    transform: translateX(0);
  }

  .sidebar__close-btn {
    display: none;
  }
}

.sidebar.sidebar--open {
  transform: translateX(0);
}

.sidebar .sidebar__close-btn {
  right: -40px;
}

.sidebar .sidebar__close-btn:focus {
  outline: none;
}

.sidebar.sidebar--open .sidebar__close-btn {
  right: 10px;
}

.sidebar.sidebar--open .sidebar__close-btn:focus {
  outline: none;
}

.sidebar-nav {
  padding: 0 20px;
  /* margin-top: 100px; */
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.btn-link {
  background: none;
  border: none;
  padding: 0;
  text-align: -webkit-left;

  &:focus {
    outline: none;
  }
}

.sidebar__close-btn {
  padding: 0;
  border: 0;
  color: #000000;
  background: transparent;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 80px;
}

.sidebar-item {
  padding-bottom: 12px;
  position: relative;
}

.sidebar-item.sidebar-item--building-current .sidebar-item__text {
  color: #02ca69;
}

.sidebar-item.sidebar-item--building-current .sidebar-item__text:hover {
  color: #02ca69;
}

.sidebar-item > .sidebar-item__arrow {
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 2px;
}

.sidebar-item > button > .sidebar-item__icon {
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
}

.sidebar-item > button .sidebar-item__text {
  color: #747894;
  padding-left: 32px;
  padding-right: 20px;
  display: inline-block;
}

.sidebar-item--current > button .sidebar-item__text,
.sidebar-item--current > button:hover .sidebar-item__text {
  color: #2c2e3a !important;
}

.sidebar-item > button:hover .sidebar-item__text {
  color: #747894;
  text-decoration: none;
}

.sidebar-item a.is-current {
  color: #02ca69;
}

.sidebar-item.sidebar-item--current .sidebar-item__text a {
  color: #747894;
}

.sidebar-item__zones .sidebar-item__text.sidebar-item__text--current a {
  color: #02ca69;
  font-size: 18px;
  font-weight: bold;
}

.sidebar-item.sidebar-item--current > span > .sidebar-item__text {
  color: #1f2344;
}

.sidebar-item__subitems a {
  color: #747894;
}

.sidebar-item__text .sidebar-item__arrow {
  color: #747894;
}

.sidebar-item__text.sidebar-item__text--current .sidebar-item__arrow {
  color: #1f2344;
}

.sidebar-item a:hover {
  text-decoration: none;
}

.sidebar-item__text {
  padding-left: 8px;
  font-size: 18px;
  cursor: pointer;
}

.sidebar-item__zones .sidebar-item__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-item__zones .sidebar-item__text a {
  color: #747894;
  display: inline-block;
  max-width: calc(100% - 20px);
  width: 100%;
}

.sidebar-item__arrow {
  float: right;
  cursor: pointer;
}

.sidebar-item__arrow.sidebar-item__arrow--down {
  transform: rotate(90deg);
  transform-origin: center;
  min-width: 14px;
}

.sidebar-item__subitems {
  padding-left: 7px;
  height: 0;
  overflow: hidden;
}

.sidebar-item__subitems.sidebar-item__subitems--open {
  height: auto;
}

.sidebar-item__subitems div {
  padding-top: 8px;
  padding-right: 20px;
  overflow-wrap: anywhere;
}

.sidebar-item__zones {
  padding-left: 21px;
  font-family: "Avenir Book", sans-serif;
  height: 0;
  overflow: hidden;
}

.sidebar-item__zones .sidebar-item__text {
  padding-left: 7px;
}

.sidebar-item__zones.sidebar-item__zones--open {
  height: auto;
}

.sidebar-item__zones > div {
  padding-top: 12px;
}

.sidebar-machinery {
  z-index: 100;
  padding: 130px 0 0;
  border-right: 1px solid #0000001f;
}

.card {
  border: 0;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 8px;
}

.nav-avatar {
  max-width: 35px;
}

.chart-container {
  background: #ffffff;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
}

.chart-container_power {
  background: #ffffff;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 8px;
  height: 60vh;
  overflow: hidden;
}

.chart-container_sound {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.chart-title {
  font-family: "Avenir", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 13px;
  color: #383948;
}

.lighting-leyend {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 1rem 1rem 1rem;
  min-height: 46px;
}

.lighting-leyend p {
  line-height: 1;
  margin-bottom: 0;
}

.lighting-leyend .lighting-leyend-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 0.5rem;
}

.lighting-leyend .lighting-leyend-threshold {
  margin: 0 2rem 0 1rem;
}

.lighting-leyend-line {
  background: #ededed;
  height: 0.5rem;
  margin-right: 0.5rem;
  width: 50px;
}

.leyend-gradient {
  height: 12px;
  margin-right: 0.5rem;
  width: 60px;
}

.leyend-point {
  border-radius: 50%;
  height: 14px;
  margin-right: 0.5rem;
  width: 14px;
}

.lighting-leyend-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .chart-container {
    max-height: 439px;
  }

  .lighting-leyend {
    margin: 0 auto;
    max-width: 550px;
  }
}

.scheduler {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.scheduler-multiple {
  display: contents;
}

@media (min-width: 769px) and (max-width: 1367px) and (min-height: 690px) {
  .scheduler {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
  }

  .scheduler:has(> .scheduler-multiple-alerts) {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1368px) {
  .scheduler {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
  }

  .scheduler:has(> .scheduler-multiple-alerts) {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* iPad Pro landscape */
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  @media (min-width: 769px) and (max-width: 1367px) and (min-height: 690px) {
    .scheduler {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
    }
  }
}

/* iPad Pro portrait */
@media only screen and (min-device-width: 834px) and (max-device-height: 1112px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .scheduler {
    grid-auto-rows: auto;
    grid-template-columns: repeat(1, 1fr);
  }
}

.number-selector {
  outline: 0 !important;
  background-color: #f3f3f3;
  font-size: 22px;
  color: #333333;
  padding: 12px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 8px;
  border: 0;
  min-width: 150px;
}

.number-selector:active {
  border: 1px solid #ccc !important;
  outline: 0 !important;
}

.number-selector:invalid {
  border: 1px solid #ccc !important;
  outline: 0 !important;
}

.scheduler .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-time__header {
  font-size: 11px;
}

.btn-success {
  background-color: #00ffc3 !important;
  border-color: #00ffc3 !important;
  color: #212529 !important;
}

.scheduler input {
  background-color: #e8e8e8;
  font-size: 12px;
  color: #383948;
  border: 0;
  border-radius: 4px;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  /*background-color: #343a40;*/
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 50px;
  /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px;
    /* Space for fixed navbar */
  }
}

.content-page {
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding: 70px 12px 65px;
}

@media (min-width: 1367px) {
  .content-page {
    margin-left: 260px;
    /* margin-right: 140px; */
    overflow: hidden;
    padding: 70px 12px 0;
    padding-bottom: 0;
    min-height: 100vh;
  }

  .content-page:has(> .container-true > .building) {
    margin-right: 0 !important;
  }
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar {
  background-color: #fff;
}

.navbar-content {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.navbar-bell {
  display: block;
  padding: 0;
  margin: 0 10px;
  border-width: 0;
  /* background-color: transparent; */
}

.navbar-bell-header {
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
  text-align: -webkit-center;
  padding-top: 0.75rem;
  background-size: 1rem;
}

.navbar-bell-header:hover {
  background-color: #32313d;
}

.navbar-bell-header:hover .ico.ico--bell-regular {
  filter: invert(100%) sepia(6%) saturate(7462%) hue-rotate(222deg)
    brightness(110%) contrast(97%);
}

.bell {
  display: block;
  font-size: 25px;
  color: #878787;
  position: relative;
  background-position: center center;
  animation: ring 1.6s linear infinite;
}

.bell-count {
  position: absolute;
  padding: 6px;
  background: #ff4f38;
  border-radius: 30px;
  margin-left: -20px;
  top: 20px;
  text-align: center;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

ul.nav > li.nav-item.title {
  text-align: center;
  background-color: #4f4ff1;
  padding: 15px;
  display: none;
}

ul.nav > li.nav-item.container-title {
  padding: 15px;
  font-weight: bold;
}

ul.nav > li.nav-item.container-element i {
  color: #90e6f6;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
}

ul.nav > li.nav-item.container-element > h4 {
  font-size: 15px;
}

ul.nav > li.nav-item.container-element > span {
  margin-left: 40px;
}

ul.nav > li.nav-item.container-element {
  padding: 15px 15px 0;
}

ul.nav > li hr {
  border-color: #0000001f;
}

ol.breadcrumb {
  background-color: #fff;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
}

ol.breadcrumb li {
  align-self: center;
  align-items: center;
}

ol.breadcrumb > li.breadcrumb-item {
  font-size: 16px;
  font-weight: bold;
}

ol.breadcrumb > li.breadcrumb-item.breadcrumb-item--inactive {
  min-width: fit-content;
}

nav.navbar {
  border-bottom: 1px solid #80808059;
}

nav.navbar a.navbar-brand {
  background-color: #fff;
  box-shadow: none;
}

.maichinery-link {
  color: #808080;
  text-decoration: none;
}

.maichinery-link:hover {
  color: #808080;
  text-decoration: none;
}

.maichinery-link:active {
  color: #808080;
  text-decoration: none;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.footer-copyright {
  color: #00000082;
  padding: 20px 30px 20px 20px;
}

img.navbar-mai-logo {
  max-height: 40px;
}

.container-login {
  margin-top: 100px;
}

.container-login h1 {
  margin-bottom: 40px;
}

.days-selector li {
  cursor: pointer;
}

.days-selector li:hover,
.days-selector li.active {
  background-color: #8ef3bc;
  color: #000;
  border-color: #8ef3bc;
}

.highcharts-credits,
#chart-wind-rose-data {
  display: none;
}

/*SWITCH*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input + .slider {
  background-color: #e8e8e8;
}

input + .slider::before {
  background-color: #383948;
}

input:checked + .slider::before {
  background-color: #00ffc3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*END SWITCH*/

.day-selected {
  margin-top: 20px;
  display: none;
}

.day-selected input::placeholder {
  color: #ced4da;
}

.day-selected input:focus {
  border-color: #8ef3bc;
  box-shadow: 0 0 0 0.2rem rgba(142, 243, 188, 0.51);
}

.multiedit {
  height: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.multiedit > span {
  display: none;
}

span.badge.badge-machi {
  color: #fff;
  background-color: #2c2172 !important;
  border-color: #2c2172 !important;
}

.btn.focus,
.btn:focus {
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgb(0 0 0 / 17%);
}

.btn-primary.btn-machinery,
.btn-primary.btn-machinery:active {
  color: #000 !important;
  background-color: #8ef3bc !important;
  border-color: #8ef3bc !important;
}

.btn.btn-orpheus,
.btn.btn-orpheus:active {
  color: #fff;
  background-color: #333333 !important;
  border-color: #333333 !important;
}

.btn.btn-orpheus:active:focus,
.btn.btn-orpheus:focus {
  box-shadow: 0 0 0 0.2rem rgba(44, 33, 114, 0.51) !important;
}

.btn-orpheus-outline {
  border-color: #2c2172 !important;
  color: #2c2172 !important;
}

.btn-orpheus-fill {
  white-space: nowrap;
  padding: 15px 21px;
  background-color: #464646;
  color: #ffffff;
}

.btn-orpheus-fill:hover,
.btn-orpheus-fill:active {
  border-color: #464646 !important;
  background-color: #464646;
  color: #ffffff !important;
}

.btn-orpheus-fill-gray {
  padding: 14px 21px;
  background-color: #373948;
  color: #ffffff;
}

.btn-orpheus-fill-gray.is-active {
  background-color: #00ffc3;
  color: #333333 !important;
}

.btn-orpheus-outline:hover,
.btn-orpheus-outline:active {
  border-color: #2c2172 !important;
  color: #2c2172 !important;
  background: transparent;
}

.btn-orpheus-outline:focus,
.btn-orpheus-outline:active {
  box-shadow: 0 0 0 0.2rem rgba(44, 33, 114, 0.51) !important;
}

.back-progress-color {
  background-color: #e9ecef;
}

.progress-orpheus {
  background-color: #8ef3bc;
  color: #000;
}

form.login {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

button#save_config {
  margin-top: 20px;
  display: none;
}

.expiration_info {
  white-space: pre-line;
  text-align: -webkit-center;
  height: -webkit-fill-available;
  align-content: center;
  font-size: larger;
  line-height: 2.5rem;
}

.expiration_info_title {
  font-size: xxx-large;
  margin-bottom: 2rem;
  color: orangered;
}

.expiration_info_expiration_date {
  color: orangered;
  display: -webkit-inline-flex;
}

.expiration_info_first {
  width: -webkit-fit-content;
  text-align: -webkit-left;
}

.expiration-date {
  display: -webkit-inline-flex;
  overflow: hidden;
  color: #00000082;
}

.info-sidebar:has(> .expiration-date) {
  background: none;
  height: fit-content;
  text-align: -webkit-right;
}

.info-sidebar {
  background: #ffffff;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.info-sidebar.energy-kpi {
  width: fit-content;
  margin-bottom: 16px;
}

.building > .info-sidebar.energy-kpi {
  margin-left: 10px;
}

@media (max-width: 1367px) {
  .info-sidebar.energy-kpi {
    width: auto;
    margin-right: 16px;
  }
}

.info-sidebar.energy-kpi.energy-kpi-building:hover {
  border-color: rgb(154 161 171 / 60%);
  box-shadow: 1px 0 15px 0 rgb(154 161 171 / 25%);
}

.info-sidebar.energy-kpi a {
  color: #313131;
  text-decoration: none;
}

.machinery-dashboard-kpis {
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.machinery-dashboard-kpis .dashboard-kpi {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 225px !important;
}

.dashboard-kpi .kpi-title {
  color: #313131;
  font-weight: bold !important;
  margin-top: 1rem;
}

.occupied-kpi:not(.not-occupied) {
  color: #5b17f9;
}

.occupied-kpi.not-occupied {
  color: #8499ab;
}

.occupied-kpi .dashboard-kpi div:not(.occupied-circle) {
  font-weight: bold !important;
  margin-top: 1rem;
}

.occupied-kpi .occupied-circle {
  background: #5b17f9;
  border: 3px solid #75779a;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.occupied-kpi.not-occupied .occupied-circle {
  background: #8499ab;
}

@media (min-width: 1370px) {
  .navbar-brand {
    margin-right: 140px;
  }
}

@media (max-width: 1370px) {
  .navbar-brand {
    margin-right: 3rem;
  }
}

.module-charts {
  position: relative;
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

.module-charts > div {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.module-charts-width {
  position: relative;
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

/* iPad Pro landscape */
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .machinery-dashboard-kpis .dashboard-kpi {
    width: auto;
  }
}

/* iPad Pro Portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .machinery-dashboard-kpis .dashboard-kpi {
    width: auto;
  }
}

/* iPad landscape */
@media only screen and (min-device-width: 1024px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .machinery-dashboard-kpis .dashboard-kpi {
    width: auto;
  }

  .module-charts {
    grid-template-columns: repeat(2, 1fr);
  }

  .module-charts-width {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-device-width: 1025px) {
  .module-charts {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  .module-charts-width {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* larger than iPad */
@media only screen and (min-device-width: 1367px) {
  .machinery-dashboard-kpis {
    flex-wrap: nowrap;
  }

  .machinery-dashboard-kpis .dashboard-kpi {
    width: 100%;
  }
}

@media (min-width: 1367px) and (max-height: 700px) {
  .machinery-dashboard-kpis .dashboard-kpi .ico {
    min-width: 30px;
    min-height: 30px;
  }
}

.dashboard-kpis-bottom {
  margin-bottom: 30px;
}

.dashboard-kpis-bottom {
  padding-bottom: 5px;
  color: #808080;
}

.preferences-container a {
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .preferences-container {
    width: -webkit-fill-available;
  }
}

.machinery-dashboard-kpis .dashboard-kpi {
  text-align: center;
}

.machinery-dashboard-kpis .dashboard-kpi div {
  font-weight: 500;
}

.machinery-dashboard-kpis .dashboard-kpi span {
  font-weight: 900;
}

.machinery-dashboard-kpis .dashboard-kpi h4 {
  font-size: 15px;
}

.machinery-dashboard-kpis .dashboard-kpi h4 i {
  color: #90e6f6;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 15px;
}

.navbar a {
  color: #000000;
}

.chart-default-style {
  max-height: 400px;
}

p.activity-gauge-label {
  position: relative;
  top: -235px;
  left: 46%;
  height: 80px;
  width: 120px;
  font-size: 25px;
  font-weight: 600;
  color: #6bdc85;
  text-align: center;
}

@media only screen and (max-width: 2600px) {
  p.activity-gauge-label {
    left: 45%;
  }
}

@media only screen and (max-width: 2200px) {
  p.activity-gauge-label {
    left: 44%;
  }
}

@media only screen and (max-width: 1920px) {
  p.activity-gauge-label {
    left: 43%;
  }
}

@media only screen and (max-width: 1600px) {
  p.activity-gauge-label {
    left: 40%;
  }
}

@media only screen and (max-width: 1400px) {
  p.activity-gauge-label {
    left: 40%;
  }
}

@media only screen and (max-width: 1110px) {
  p.activity-gauge-label {
    left: 36%;
  }
}

@media only screen and (max-width: 900px) {
  p.activity-gauge-label {
    left: 32%;
  }
}

@media only screen and (max-width: 768px) {
  main.main-dashboard {
    padding: 225px 0 0;
  }

  .machinery-dashboard-kpis .dashboard-kpi {
    max-width: 100%;
    margin-bottom: 15px;
  }

  p.activity-gauge-label {
    left: 40%;
  }
}

@media only screen and (max-width: 570px) {
  p.activity-gauge-label {
    left: 36%;
  }
}

@media only screen and (max-width: 450px) {
  p.activity-gauge-label {
    left: 32%;
  }
}

@media only screen and (max-width: 450px) {
  p.activity-gauge-label {
    left: 30%;
  }
}

.react-datepicker-popper {
  z-index: 3 !important;
}

nav ol.breadcrumb li.breadcrumb-item a {
  color: #2c2e3a;
  font-weight: normal;
}

nav ol.breadcrumb li.breadcrumb-item a:hover {
  color: #2c2172;
}

nav .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  font-weight: normal;
  color: #9d9d9d;
}

.card {
  cursor: auto;
}

.btn-large {
  border-radius: 13px;
}

.btn:hover {
  box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.17);
  color: #fff;
}

.btn-large {
  padding: 22px 60px;
  font-size: 16px;
  text-transform: uppercase;
}

.btn-link {
  color: #2c2172;
}

.btn-link:hover {
  box-shadow: none;
}

.btn-link:hover {
  color: #2c2172;
}

.btn-thermostat.btn.btn-danger {
  background: #fff289;
  color: #3c3c3c;
  border: none;
}

.btn-thermostat.btn.btn-danger:hover {
  background: #fff289;
  color: #3c3c3c;
}

nav button.btn-link.nav-link.maichinery-link {
  color: #808080 !important;
}

.btn-outline-primary.btn-orpheus-outline:active {
  background: white !important;
}

.react-datepicker {
  width: 100%;
}

.react-datepicker .react-datepicker__navigation {
  outline: none;
  top: 10px;
}

.react-datepicker .react-datepicker__header {
  background-color: transparent;
  border: 0;
}

.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  background-color: #e7e7e7;
  color: #333333;
  margin-bottom: 4px;
  padding: 6px 0;
}

.react-datepicker .react-datepicker__header .react-datepicker__day-names {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f4f4f4;
  margin-bottom: 4px;
  border: 0;
}

.react-datepicker
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  font-family: "Avenir", sans-serif;
  width: auto;
  color: #333333;
  font-weight: 900;
}

.react-datepicker .react-datepicker__month {
  background-color: #f4f4f4;
  margin: 0;
}

.react-datepicker .react-datepicker__day {
  color: #333333;
  width: auto;
}

.react-datepicker .react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker
  .react-datepicker__navigation
  .react-datepicker__navigation--next {
  top: 18px;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--highlighted {
  background-color: #333333 !important;
  color: #ffffff;
  position: relative;
}

.react-datepicker
  .react-datepicker__day.react-datepicker__day--highlighted:hover {
  background: #282828;
}

.react-datepicker .react-datepicker__month-container {
  margin: 0;
  width: 100%;
}

.react-datepicker .react-datepicker__week {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(7, 1fr);
}

.react-datepicker__navigation--next {
  border-left-color: #333 !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #333 !important;
}

.react-datepicker__close-icon {
  padding: 0 1rem 0 0 !important;
}

.react-datepicker__close-icon::after {
  background-color: #00ffc3 !important;
  color: #373948 !important;
}

.react-datepicker .react-datepicker__day--today {
  background: transparent !important;
  font-weight: 500;
}

.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:not([aria-disabled="true"]):hover {
  background-color: #eeeeee !important;
}

.react-datepicker__day--in-range,
.range-picker
  .react-datepicker__day--in-range.react-datepicker__day--keyboard-selected,
.range-picker .react-datepicker__day--selecting-range-end,
.range-picker .react-datepicker__day--selected,
.range-picker .react-datepicker__day--selected:hover {
  background-color: rgba(0, 255, 195, 1) !important;
}

.range-picker .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.range-picker .react-datepicker__day--in-selecting-range {
  background-color: rgba(0, 255, 195, 0.5) !important;
}

.range-picker .react-datepicker__day--range-start {
  color: #333 !important;
}

.welcome {
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
}

.welcome h1 {
  color: #00ffc3;
  font-weight: 900;
  font-size: 3rem;
}

.welcome h2 {
  display: inline-block;
  width: 100%;
  max-width: 780px;
  font-size: 1.6rem;
  line-height: 1.6;
}

.welcome img {
  margin: 20px auto;
  display: block;
  max-width: 100px;
}

#container-alert {
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  visibility: visible;
  display: block;
  z-index: 1031;
  align-items: center;
  justify-content: center;
  background-color: rgb(248, 248, 248, 0.9);
}

.reveal-modal {
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 41;
  top: 35%;
  border-radius: 15px;
  text-align: center;
}

.alert-box {
  background: red;
  color: white;
  padding: 10px 30px;
  font-size: 1.4em;
  border-radius: 15px;
}

.alert-svg {
  margin-bottom: 15px;
  height: 7em;
}

.close-reveal-modal {
  color: red;
  font-size: 3.5em;
  cursor: pointer;
  display: inline-block;
}

@media (min-width: 768px) {
  .welcome {
    margin-top: 120px;
  }

  .welcome h1 {
    font-size: 4rem;
  }

  .welcome h2 {
    font-size: 2rem;
  }
}

.container-true {
  display: flex;
  min-height: calc(100vh - 70px);
  flex-direction: column;
}

.container-true main {
  flex: 1;
}

.breadcrumbs {
  display: block;
}

@media (min-width: 1200px) {
  .breadcrumbs {
    display: block;
  }
}

.toolbar {
  justify-content: flex-end;
  max-height: 5.05rem;
}

@media (min-width: 1200px) {
  .toolbar {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.loading {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  color: #00ffc3;
}

.loading div {
  font-size: 4rem;
}

.loader-container {
  align-items: center;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin: 1rem;
  width: 4rem;
}

.loader {
  font-size: 10px;
  text-indent: -9999em;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: #00ffc3;
  background: -moz-linear-gradient(left, #00ffc3 10%, rgba(69, 62, 230, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #00ffc3 10%,
    rgba(69, 62, 230, 0) 42%
  );
  background: -o-linear-gradient(left, #00ffc3 10%, rgba(69, 62, 230, 0) 42%);
  background: -ms-linear-gradient(left, #00ffc3 10%, rgba(69, 62, 230, 0) 42%);
  background: linear-gradient(to right, #00ffc3 10%, rgba(69, 62, 230, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #00ffc3;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.loader:after {
  background: #f3f8fb;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 520px) {
  .loader {
    height: 2rem;
    width: 2rem;
  }
}

.g-row {
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: 1fr 1fr 1fr auto;
  grid-auto-flow: column;
}

.breadcrumb {
  flex-wrap: nowrap;
}

.breadcrumb-item.breadcrumb-item--inactive a {
  cursor: default;
}

nav ol.breadcrumb li.breadcrumb-item--inactive a:hover {
  text-decoration: none;
  color: #2c2e3a;
}

.breadcrumb-item .active {
  width: 100%;
}

.btn-small {
  padding: 9px 13px;
}

.language-selector {
  position: fixed;
  right: 20px;
  top: 80px;
  display: inline-flex;
}

.language-selector .dropdown button {
  height: -webkit-fill-available;
  background-color: #00e6b0;
  border: none;
}

.language-selector .dropdown button:hover {
  background-color: #00e6b0;
}

.language-selector .dropdown button:focus {
  background-color: #00e6b0 !important;
  box-shadow: none !important;
}

.language-selector .dropdown button:active {
  background-color: #00e6b0 !important;
  box-shadow: none;
}

.hide {
  display: none;
}

.building-zones {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1rem;
}

.building-zone-link {
  width: min-content;
}

.building-zone {
  align-items: center;
  border-radius: 8px;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
  color: #2c2e3a;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  margin: 16px 16px 0 0;
  min-height: 405px;
  min-width: 300px;
  padding: 3rem 4rem;
  text-transform: uppercase;
  width: 300px;
}

.building-zone a {
  color: #fff;
}

.building-zones a:hover,
.chart-container a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1366px) {
  .building {
    margin-top: 3rem;
  }
}

.bad-quality {
  background: linear-gradient(135deg, #fe928f 0%, #fe928f 100%);
}

.medium-quality {
  background: linear-gradient(135deg, #fffc98 0%, #fffc98 100%);
}

.good-quality {
  background: linear-gradient(135deg, #90fec9 0%, #8ffec9 100%);
}

.no-quality {
  background: linear-gradient(135deg, #d8e3dd 0%, #d8e3dd 100%);
}

.building-zone .building-zone-title {
  margin-bottom: 32px;
  position: relative;
  text-align: center;
}

.building-zone-title-disabled {
  color: #555555;
  text-align: center;
  margin-top: 3.5rem;
}

.css-1n7v3ny-option {
  background-color: transparent;
}

.building-zone .building-zone-title:after {
  border-bottom: 1px solid #fff;
  content: " ";
  height: 3px;
  opacity: 0.2;
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 100%;
}

.building-zone .building-zone-indicators {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  justify-content: center;
}

.building-zone .building-zone-indicators .building-zone-indicator {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
}

.building-zone .building-zone-indicator span {
  font-size: 1.75rem;
}

.building-zone .building-zone-indicator h3 {
  font-size: 0.8rem;
  text-align: center;
}

.building-zone .building-zone-indicator.status-indicator h3 {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 1rem 0;
}

.mode-chart {
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  margin: 0;
  padding: 1rem;
  width: 100%;
}

.mode-chart .building-zone-indicators {
  align-items: flex-start;
  flex-direction: row;
  height: 100%;
  margin: 16px 0;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
}

.mode-chart .building-zone-indicator {
  border-radius: 8px;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-right: 2rem;
  max-width: 230px;
  padding: 8px 16px;
  position: relative;
}

.mode-chart .building-zone-indicator:first-child {
  max-width: 150px !important;
}

.mode-chart .building-zone-indicator h3 {
  font-size: 1rem;
  text-align: center;
}

.mode-chart .building-zone-indicator.status-indicator h3 {
  font-size: 1.8rem;
}

.mode-chart .building-zone-indicator span {
  font-size: 3rem;
}

.mode-chart .building-zone-indicator:after {
  border-right: 1px solid #fff;
  content: " ";
  height: 30%;
  opacity: 0.2;
  position: absolute;
  right: -1rem;
  width: 3px;
}

.mode-chart .building-zone-indicator:last-child {
  margin-right: 0;
}

.mode-chart .building-zone-indicator:last-child:after {
  content: none !important;
}

.lowercase-text {
  text-transform: lowercase !important;
}

@media screen and (max-width: 623px) {
  .building-zones {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 623px),
  (min-width: 1024px) and (max-width: 1248px),
  (min-width: 1366px) and (max-width: 1700px) {
  .mode-chart.building-zone {
    justify-content: center;
  }

  .mode-chart .building-zone-indicators {
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 0;
  }

  .mode-chart .building-zone-indicator {
    margin: 0 0 2rem 0;
    max-height: 80px;
  }

  .mode-chart .building-zone-indicator:after {
    border-bottom: 1px solid #fff;
    content: " ";
    height: 3px;
    opacity: 0.2;
    position: absolute;
    bottom: -1rem;
    right: 0;
    width: 100%;
  }

  .mode-chart .building-zone-indicator:last-child {
    margin-bottom: 0;
  }

  .mode-chart .building-zone-indicator:last-child:after {
    content: none !important;
  }
}

.occupation-leyend-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  width: 100%;
}

.occupation-leyend-group .occupation-leyend-item {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.occupation-leyend-group .occupation-leyend-item p {
  margin: 0 0.5rem 0 0;
}

.occupation-leyend-group.rotated-leyend {
  margin: 0 auto;
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .occupation-leyend-group.rotated-leyend {
    position: absolute;
    left: -50px;
    top: 0;
    transform: rotate(-90deg);
    width: inherit;
  }
}

/**
*** Notifications
**/

.notifications-bar {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.notifications-bar a:hover {
  text-decoration: none;
}

.notifications-bar div {
  margin: 0 0.5rem;
}

.notifications-bar .notification-summary.read {
  border-radius: 50%;
  cursor: pointer;
  padding: 0.75rem 0.9rem;
}

.notifications-bar .notification-summary.read .fa-bell {
  font-size: 1.2rem;
}

.notifications-bar .notification-summary.read:hover {
  background: #32313d;
}

.notifications-bar .notification-summary.read:hover a {
  color: #fff;
}

.notifications-bar .notification-message {
  background: #d7dbff;
  border-radius: 20px;
  color: #000;
  padding: 0.5rem 1rem;
}

.notifications-bar .notification-message p {
  margin-bottom: 0;
  text-decoration: none;
}

.notifications-bar .notification-message-close {
  background: #2e2f3d;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
}

.notifications-pending {
  background: #ff002c;
  border-radius: 20px;
  color: #fff;
  max-height: 37px;
  padding: 0.5rem 0.8rem;
  max-width: 80px;
  position: relative;
  z-index: 2;
}

.notifications-pending.no-notifications,
.notifications-pending.no-notifications .notifications-pending-number:after {
  background: #7e8098 !important;
}

.notifications-pending .notifications-pending-number {
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 0.5rem;
  padding: 0.2rem 0.5rem;
  position: relative;
}

.notifications-pending .notifications-pending-number:after {
  background: #ff002c;
  content: "";
  height: 10px;
  position: absolute;
  right: -2px;
  top: 4px;
  transform: rotate(-30deg);
  width: 8px;
}

.notifications-filter-dropdown button {
  width: 167px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 8px 16px;
  margin-right: 16px;
}

.notifications-buildings-dropdown button {
  align-self: center;
  width: 187px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 8px 16px;
  color: #fff;
  background-color: #9391e0;
  border-color: #9391e0;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
}

.notifications-buildings-dropdown button::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.notifications-buildings-dropdown button:hover {
  background-color: #7472b1;
  border-color: #7472b1;
}

.dropdown-building {
  display: flex;
  justify-content: space-between;
}

.dropdown-building-name {
  margin-right: 1rem;
}

.dropdown-multiselect {
  min-width: 10rem;
  align-self: center;
  margin-right: 16px;
}

.dropdown-multiselect div {
  padding: 3px 4px;
}

.dropdown-multiselect div {
  border-color: hsl(0, 0%, 70%);
  box-shadow: none;
}

.dropdown-multiselect div:hover {
  border-color: hsl(0, 0%, 70%);
}

.dropdown-building-name:hover {
  /* background-color: #9c9c9c; */
  box-shadow: #000;
}

.notifications-filter {
  align-items: center;
  /* background: #f3f8fb; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 0 -0.5rem;
  padding: 1.5rem 0 1.5rem 77px;
  position: relative;
  z-index: 3;
}

.notifications-filter-tags {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  /* overflow: auto; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notifications-filter-tags::-webkit-scrollbar {
  display: none;
}

.notifications-filter .notification-tag {
  border-radius: 30px;
  cursor: pointer;
  margin: 0 0.5rem;
  max-height: 37px;
  padding: 0.5rem 1.5rem;
}

.notifications-filter .notification-tag span {
  color: #2b2940;
  white-space: nowrap;
}

.notifications-filter .notification-tag.selected span {
  color: #fff;
  font-weight: bold;
}

.notifications-filter .notifications-filter-pending {
  position: relative;
}

.notifications-filter .notifications-filter-pending .filter-blur {
  background: #f3f8fb;
  filter: blur(10px);
  height: 37px;
  position: absolute;
  top: 0;
  left: -15px;
  width: 70px;
}

.notifications-list {
  height: calc(100vh - 375px);
  overflow: auto;
  padding-bottom: 80px;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notifications-list::-webkit-scrollbar {
  display: none;
}

.notifications .notifications-list-blur {
  bottom: 90px;
  height: 80px;
  filter: blur(10px);
  opacity: 1;
  position: absolute;
  width: 78vw;
}

.notification-box {
  display: flex;
}

.notification-checkmark {
  margin: 24px;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.notification {
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 5px 0 rgba(154, 161, 171, 0.15);
  display: flex;
  flex-direction: column;
  margin: 1.2rem 0.9rem;
  padding: 1rem 2rem;
  position: relative;
  width: -webkit-fill-available;
}

.notification.no-notifications {
  margin-left: 4.5rem;
  margin-top: 3.5rem;
}

.notification-multiple {
  box-shadow: 0px 0px 2px 2px rgba(154, 161, 171, 0.149),
    0 0 rgba(154, 161, 171, 0.149), 7px -7px 0 -2px #fff,
    7px -7px rgba(154, 161, 171, 0.15), 12px -12px 0 -2px #fff,
    12px -12px rgba(154, 161, 171, 0.15);
}

.notifications-common {
  display: flex;
  align-items: center;
}

.notification .notification-dot {
  border-radius: 50%;
  height: 9px;
  width: 9px;
  position: absolute;
  left: 15px;
  top: 21px;
}

.notification .notification-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.85rem 0 0.85rem 0;
}

.notification-body {
  display: flex;
  align-items: center;
  color: darkgray;
}

.notification-body-text {
  margin-left: 8px;
}

.notification-body_icon {
  font-size: 18px;
}

.notification-icons {
  margin-bottom: 0 !important;
}

.archive-icon {
  background-image: url("data:image/svg+xml,%3Csvg id='confirmacion' xmlns='http://www.w3.org/2000/svg' width='30.748' height='30.748' viewBox='0 0 30.748 30.748'%3E%3Cg id='Group_25' data-name='Group 25' transform='translate(10.249 10.89)'%3E%3Cg id='Group_24' data-name='Group 24'%3E%3Cpath id='Path_10' data-name='Path 10' d='M139.8,136.377a1.281,1.281,0,0,0-1.812,0l-5.5,5.5-2.3-2.3a1.281,1.281,0,0,0-1.812,1.812l3.2,3.2a1.28,1.28,0,0,0,1.812,0l6.406-6.406A1.281,1.281,0,0,0,139.8,136.377Z' transform='translate(-128.002 -136.002)' fill='%23CACBD7'/%3E%3C/g%3E%3C/g%3E%3Cg id='Group_27' data-name='Group 27' transform='translate(0 0)'%3E%3Cg id='Group_26' data-name='Group 26'%3E%3Cpath id='Path_11' data-name='Path 11' d='M29.466,14.093a1.282,1.282,0,0,0-1.281,1.281,12.81,12.81,0,1,1-3.72-9.027,1.281,1.281,0,1,0,1.818-1.805,15.374,15.374,0,1,0,4.464,10.832A1.282,1.282,0,0,0,29.466,14.093Z' transform='translate(0 0)' fill='%23CACBD7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 2rem;
  height: 2rem;
  width: 2rem;
}

.snooze-icon {
  background-image: url("../Img/icons/snooze_inactive.svg");
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 2.7rem;
  width: 2.7rem;
}

.notification .notification-title.archived .archive-icon {
  background-image: url("data:image/svg+xml,%3Csvg id='confirmacion' xmlns='http://www.w3.org/2000/svg' width='30.748' height='30.748' viewBox='0 0 30.748 30.748'%3E%3Cg id='Group_25' data-name='Group 25' transform='translate(10.249 10.89)'%3E%3Cg id='Group_24' data-name='Group 24'%3E%3Cpath id='Path_10' data-name='Path 10' d='M139.8,136.377a1.281,1.281,0,0,0-1.812,0l-5.5,5.5-2.3-2.3a1.281,1.281,0,0,0-1.812,1.812l3.2,3.2a1.28,1.28,0,0,0,1.812,0l6.406-6.406A1.281,1.281,0,0,0,139.8,136.377Z' transform='translate(-128.002 -136.002)' fill='%234CDEBF'/%3E%3C/g%3E%3C/g%3E%3Cg id='Group_27' data-name='Group 27' transform='translate(0 0)'%3E%3Cg id='Group_26' data-name='Group 26'%3E%3Cpath id='Path_11' data-name='Path 11' d='M29.466,14.093a1.282,1.282,0,0,0-1.281,1.281,12.81,12.81,0,1,1-3.72-9.027,1.281,1.281,0,1,0,1.818-1.805,15.374,15.374,0,1,0,4.464,10.832A1.282,1.282,0,0,0,29.466,14.093Z' transform='translate(0 0)' fill='%234CDEBF'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.notification h2 {
  color: #2b2940;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.notification p {
  color: #575665;
  line-height: 1.5rem;
}

.notification .notification-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin: 1rem -.2rem; */
}

.notification .notification-details-zone {
  color: #7b7986;
  font-weight: bold;
}

.notification .notification-details-text {
  cursor: pointer;
}

.notification span {
  color: #9392a0;
  margin: 0 0.2rem;
}

.notification-button span {
  color: #2b2940;
}

.notification-button.selected {
  background: #00ffc3;
}

.notification-button.selected span {
  color: #fff;
}

.notification-button .archive-icon {
  height: 2rem;
  width: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg id='confirmacion' xmlns='http://www.w3.org/2000/svg' width='30.748' height='30.748' viewBox='0 0 30.748 30.748'%3E%3Cg id='Group_25' data-name='Group 25' transform='translate(10.249 10.89)'%3E%3Cg id='Group_24' data-name='Group 24'%3E%3Cpath id='Path_10' data-name='Path 10' d='M139.8,136.377a1.281,1.281,0,0,0-1.812,0l-5.5,5.5-2.3-2.3a1.281,1.281,0,0,0-1.812,1.812l3.2,3.2a1.28,1.28,0,0,0,1.812,0l6.406-6.406A1.281,1.281,0,0,0,139.8,136.377Z' transform='translate(-128.002 -136.002)' fill='%23ffffff'/%3E%3C/g%3E%3C/g%3E%3Cg id='Group_27' data-name='Group 27' transform='translate(0 0)'%3E%3Cg id='Group_26' data-name='Group 26'%3E%3Cpath id='Path_11' data-name='Path 11' d='M29.466,14.093a1.282,1.282,0,0,0-1.281,1.281,12.81,12.81,0,1,1-3.72-9.027,1.281,1.281,0,1,0,1.818-1.805,15.374,15.374,0,1,0,4.464,10.832A1.282,1.282,0,0,0,29.466,14.093Z' transform='translate(0 0)' fill='%23ffffff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.notification-button.selected .archive-icon {
  background-image: url("data:image/svg+xml,%3Csvg id='confirmacion' xmlns='http://www.w3.org/2000/svg' width='30.748' height='30.748' viewBox='0 0 30.748 30.748'%3E%3Cg id='Group_25' data-name='Group 25' transform='translate(10.249 10.89)'%3E%3Cg id='Group_24' data-name='Group 24'%3E%3Cpath id='Path_10' data-name='Path 10' d='M139.8,136.377a1.281,1.281,0,0,0-1.812,0l-5.5,5.5-2.3-2.3a1.281,1.281,0,0,0-1.812,1.812l3.2,3.2a1.28,1.28,0,0,0,1.812,0l6.406-6.406A1.281,1.281,0,0,0,139.8,136.377Z' transform='translate(-128.002 -136.002)' fill='%23ffffff'/%3E%3C/g%3E%3C/g%3E%3Cg id='Group_27' data-name='Group 27' transform='translate(0 0)'%3E%3Cg id='Group_26' data-name='Group 26'%3E%3Cpath id='Path_11' data-name='Path 11' d='M29.466,14.093a1.282,1.282,0,0,0-1.281,1.281,12.81,12.81,0,1,1-3.72-9.027,1.281,1.281,0,1,0,1.818-1.805,15.374,15.374,0,1,0,4.464,10.832A1.282,1.282,0,0,0,29.466,14.093Z' transform='translate(0 0)' fill='%23ffffff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  color: #fff;
}

.notification-filter-action {
  position: relative;
}

.notification-button {
  align-items: center;
  background: #a6a9c5;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: 80px;
  margin-right: 14.4px;
  justify-content: center;
  padding: 0.5rem 1rem;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
}

.notification-filter-action .filter-blur {
  background: #f3f8fb;
  filter: blur(10px);
  height: 70px;
  position: absolute;
  top: 0;
  left: -55px;
  width: 70px;
  z-index: 1;
}

@media screen and (max-width: 670px) {
  .notifications .notifications-list-blur {
    display: none;
  }

  .notification .notification-title {
    position: relative;
  }

  .notification .notification-title h2 {
    margin: 0;
  }

  .notification .notification-title .fa-check-circle {
    position: absolute;
    right: -25px;
    top: -60px;
  }

  .notifications-filter-tags {
    max-width: 85%;
  }

  .notifications-pending {
    width: 75px;
  }

  .dropdown-multiselect {
    min-width: 14rem;
    align-self: auto;
    margin-right: 0;
  }

  .notifications-filter {
    justify-content: center;
    margin: 0.65rem 0 1.5rem 0;
  }

  .notification-filter-action {
    top: -25px;
    margin-left: 18px;
  }

  .notification-filter-action .filter-blur {
    display: none;
  }

  .notifications-filter .date-filter {
    flex-wrap: wrap;
    overflow: hidden;
    max-width: 100%;
  }

  .notifications-filter .notifications-filter-pending {
    align-self: flex-end;
  }

  .notifications-filter .notifications-filter-tags {
    flex-direction: column;
    margin: 1rem 0 0 -0.5rem;
    max-width: 100%;
    height: 7rem;
    justify-content: space-between;
    width: -webkit-fill-available;
  }

  .notifications-list {
    height: calc(90vh - 375px);
  }

  .notification-button .fa-check-circle {
    margin-right: 10px;
  }

  .navbar {
    flex-wrap: nowrap;
  }

  .notifications-bar {
    margin: 0;
    justify-content: space-between;
  }

  .notification-message,
  .notification-message-close {
    display: none;
  }

  .notifications-pending {
    width: 100px;
  }

  .notifications-bar {
    position: absolute;
    bottom: -50px;
    right: 4px;
  }

  .dropbtn {
    width: -webkit-fill-available !important;
  }
}

@media screen and (max-width: 1370px) {
  .notifications {
    margin-top: 2rem;
  }
}

/**
*** Snooze Popup
**/
.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}

.popup {
  margin: auto;
  top: 15rem;
  padding: 35px;
  background: #fff;
  border-radius: 5px;
  width: 17rem;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 10px;
  right: 15px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  cursor: pointer;
  color: #000;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

.popup-body {
  display: flex;
  margin-top: 25px;
}

.popup-input {
  width: 3rem;
  text-align: center;
  margin-right: 15px;
  font-size: 1rem;
}

.popup-ok-button {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 3rem;
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
  background: #fff;
  border: 1px solid #d5d9d9;
  font-size: 13px;
  height: 31px;
  padding: 0 11px;
  text-align: center;
  font-weight: 400;
  color: #0f1111;
}

.popup-ok-button:hover {
  background-color: #f7fafa;
  border-color: #d5d9d9;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}

/**
*** Notifications
**/

/**
*** Date filter
**/

.date-filter {
  display: flex;
  flex-direction: row;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.date-filter::-webkit-scrollbar {
  display: none;
}

.date-filter .date-filter-year {
  display: flex;
  flex-direction: row;
}

.date-filter .year-element,
.date-filter .month-element {
  background: #e6e6e6;
  border-radius: 20px;
  color: #000;
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0.5rem 1.5rem;
}

.year-element.unviewed,
.month-element.unviewed {
  border: 2.5px solid rgba(255, 0, 44, 0.3);
}

.date-filter .year-element span,
.date-filter .month-element span {
  color: #151515;
}

.date-filter .year-element.selected {
  background: #7e8098;
}

.date-filter .year-element.selected span {
  color: #fff;
}

.date-filter .month-element {
  background: #fff;
}

.date-filter .month-element.selected {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .date-filter .date-filter-year {
    flex-wrap: wrap;
    margin: 0;
  }

  .date-filter .year-element,
  .date-filter .month-element {
    margin: 0.25rem;
  }
}

/**
*** Date filter
**/

.squeleton-line {
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  height: 1rem;
  margin: 1rem 0;
}

.fxo-widget-iframe {
  bottom: 0 !important;
  right: 0 !important;
}

.sidebar-device-settings {
  padding: 1rem;
  z-index: 2;
}

.device-settings .device-settings-head {
  color: #2c2e3a;
  font-weight: bold;
  text-align: center;
}

.device-settings .device-setting {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.device-settings .device-setting .device-setting-name {
  color: #353535;
  line-height: 1.1;
  margin: 0 2rem;
}

.device-settings .device-setting .switch {
  height: 17px;
  margin: 1rem 0;
  width: 30px;
}

.device-settings .device-setting .switch .round:before {
  bottom: 2px;
  left: 2px;
  height: 13px;
  width: 13px;
}

.device-settings .device-setting .switch input:checked + .round:before {
  transform: translateX(13px);
}

@media screen and (min-width: 1367px) {
  .sidebar-device-settings {
    padding: 0;
  }

  .device-settings {
    display: flex;
    height: fit-content;
    justify-content: flex-start;
  }

  .device-settings .device-settings-head {
    margin-left: 2rem;
  }
}

.date-picker {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 170px;
  overflow: hidden;
  width: 100%;
}

.date-picker input {
  background: #f1f1f1;
  border: 0;
  color: #333333;
  font-size: 1.1rem;
  height: inherit;
  /* min-height: 71.5px; */
  text-align: center;
}

@media screen and (max-width: 768px) {
  .date-picker input {
    min-height: 4.05rem;
  }

  .btn-orpheus-fill {
    padding: 19px 21px;
  }
}

.date-picker input:focus {
  background: #f1f1f1;
  color: #333333;
}

div:has(> input.form-control) {
  height: 100%;
}

.date-picker .react-datepicker__triangle {
  display: none;
}

.date-picker .react-datepicker {
  border-radius: 8px;
  border: 0;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 0 2rem 1rem 2rem;
}

.date-picker .react-datepicker-wrapper {
  width: 100%;
  height: -webkit-fill-available;
}

.date-picker .react-datepicker .react-datepicker__current-month,
.date-picker .react-datepicker .react-datepicker__day-names,
.date-picker .react-datepicker .react-datepicker__month {
  background: #fff;
}

.date-picker
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  color: #878996;
  font-weight: normal;
  text-transform: capitalize;
}

.date-picker .react-datepicker .react-datepicker__day {
  color: #484848;
}

.date-picker .react-datepicker .react-datepicker__day--disabled {
  color: #ccc !important;
}

.date-picker
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__current-month {
  color: #878996;
  font-weight: normal;
}

.date-picker:not(.range-picker) .react-datepicker__day--selected {
  background-color: #333333;
  color: #fff !important;
}

.date-picker:not(.range-picker) .react-datepicker__day:hover {
  background-color: transparent !important;
  color: #484848 !important;
}

.date-picker:not(.range-picker) .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
  color: #484848 !important;
}

.date-picker:not(.range-picker) .react-datepicker__day--selected:hover {
  background-color: #747799 !important;
  color: #fff !important;
}

.toolbar {
  flex-direction: column;
}

.toolbar .btn-toolbar.mr-3 {
  margin-right: 0 !important;
}

.building .toolbar {
  margin-left: 10px;
}

.toolbar .btn-orpheus-fill {
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .date-picker .react-datepicker-popper {
    position: static !important;
    transform: none !important;
    width: 94vw;
  }

  .toolbar {
    max-height: 7rem;
  }

  .info-sidebar {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 768px) {
  .toolbar {
    flex-direction: row;
  }

  .toolbar .btn-toolbar.mr-3 {
    margin-right: 1rem !important;
  }
}

.content-picker {
  height: fit-content;
}

.general-picker {
  align-self: center;
  width: fit-content;
  margin: auto;
  border: solid 1px #5a5a5a;
  border-radius: 8px;
  display: flex;
  background-color: #fff;
}

.picker {
  padding: 0.75rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.05rem;
  width: 10rem;
}

@media screen and (max-width: 768px) {
  .picker {
    width: -webkit-fill-available;
  }

  .general-picker {
    width: -webkit-fill-available;
    margin: 0;
  }
}

.picker-selected {
  background-color: #00ffc3ce !important;
}

.picker:hover {
  background-color: #00ffc385;
}

.preferences-card {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 8px 12px;
}

.preferences-card.box {
  margin-bottom: 0.85rem;
}

.preferences-text {
  font-size: 1rem;
}

.preferences-list-checkbox {
  width: 55%;
  display: flex;
  justify-content: space-around;
}

.preferences-checkbox {
  transform: scale(1.5);
}

.preferences-checkbox-active {
  margin-right: 3.5rem;
}

@media screen and (max-width: 480px) {
  .preferences-checkbox-active {
    margin-right: 1rem;
  }

  .preferences-checkbox {
    transform: scale(1.25);
  }

  .ico.ico--power-off-solid,
  .ico.ico--envelope-regular,
  .ico.ico--webpush_browser,
  .ico.ico--whatsapp {
    transform: scale(0.85);
  }
}

.select-dropdown,
.select-dropdown * {
  position: relative;
  box-sizing: border-box;
  border: none;
  width: auto;
  padding: 0.5rem;
  /* margin-bottom: 1.5rem; */
  margin: 80px 1rem 1.5rem 1rem;
}

.select-dropdown {
  position: relative;
  background-color: #efefef;
  border-radius: 4px;
}

.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.select-dropdown-zonecards {
  margin: 0;
  margin-left: 10px;
}

.dropbtn {
  background-color: #a9aef0;
  border-radius: 4px;
  width: 12rem;
  color: #808080;
  border: #cccccc solid 1px;
  padding: 11px 22px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.dropbtn-filter {
  background-color: transparent;
  border: transparent;
  padding: 11px 11px;
  height: 2.8rem;
  width: 2.8rem;
  border-radius: 50%;
}

.dropbtn-filter:hover {
  background-color: #32313d;
}

.dropbtn-filter:hover .ico {
  filter: invert(100%) sepia(6%) saturate(7462%) hue-rotate(222deg)
    brightness(110%) contrast(97%);
}

.dropbtn-filter:focus {
  outline: none;
}

.dropbtn:focus {
  outline: none;
}

.dropdown {
  position: relative;
}

.dropdown-filter {
  display: inline-block;
}

.dropdown:focus {
  outline: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 0.25vw;
  width: -webkit-fill-available;
  padding: 0 15px;
  border-radius: 4px;
  background-color: hsl(0deg, 0%, 100%);
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  z-index: 1;
  box-sizing: border-box;
  max-width: 20rem;
}

.dropdown-content-date {
  margin-bottom: 1rem;
}

.dropdown-content-filter {
  width: max-content;
}

.dropdown-content:has(.dropdown-dissable) {
  width: -webkit-fill-available !important;
}

.dropdown-content-header {
  font-size: 18px;
  margin: 15px 0;
}

.dropdown-content-header-one {
  height: 5rem;
  justify-content: center;
  padding: 15px 0 8px 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%); */
}

.dropdown-content-datepicker {
  max-width: min-content;
  height: 4rem;
}

.dropdown-content-two-date {
  margin: 10px 0;
}

.dropdown-content-footer {
  display: flex;
  padding: 5px 0;
  justify-content: space-between;
}

.dropdown-content-overflow {
  overflow: auto;
  max-height: 11.5rem;
  padding: 8px 0 0 8px;
}

.dropdown-content-back,
.dropdown-content-next {
  cursor: pointer;
}

.loader-extended {
  height: 1.5rem;
  width: 1.5rem;

  &:after {
    background: #fff;
  }
}

.dropdown-content-chooseoptions {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-around;
}

.dropdown-content-language {
  height: 5rem;
  justify-content: center;
  padding: 15px 0 8px 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%); */
}

.dropdown-content-emailresponse {
  padding: 1rem 0;
  font-size: 16px;
}

.dropdown-content-two-language {
  display: flex;
  height: -webkit-fill-available;
  flex-direction: column;
  justify-content: space-evenly;
}

.dropdown-content-next .ico--plus-solid {
  transform: rotate(45deg);
  justify-content: space-between;
}

.dropdown-content-error-message {
  background-color: #f8d7da;
  border-color: #f5c2c7;
  color: #842029;
  padding: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.dropdown-content-download {
  /* margin-right: 2rem; */
  margin: 0 1rem;
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  height: auto;
  background-color: rgb(220, 220, 220);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-content-language-single {
  margin: 0 5px;
  display: flex;
}

.dropdown-content-language-single div {
  margin: 0 8px;
}

.dropdown-content-language-single div input {
  margin: 0 8px;
  transform: scale(1.25);
}

@media screen and (max-width: 768px) {
  .dropdown-content {
    /* max-height: 9rem; */
    font-size: 2.25vw;
    margin-right: 1rem;
    margin-left: -2.5rem;
  }
}

.dropdown-row {
  display: flex;
  padding: 3px 4px;
  align-items: center;
}

.dropdown-dissable {
  padding: 3px 4px;
  color: hsl(0, 0%, 60%);
  text-align: center;
}

.dropdown-building-ckeckbox {
  display: flex;
  margin: 0 8px;
}

.dropdown-building-button {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-weight: 550;
}

.dropdown-building-button:has(.ico--qrcode-solid) {
  justify-content: center;
}

.dropdown-label {
  margin-left: 0.5rem;
  margin-bottom: 0;
}

.dropdown-expand {
  cursor: pointer;
}

.dropdown-building-ckeckbox-zones {
  margin-left: 2.5rem;
}

.show {
  display: block !important;
}

.checkbox-zone-hide {
  display: none !important;
}

.dropdown-sort {
  position: relative;
  margin-left: 10px;
}

.dropdown-sort-sidebar {
  margin: 80px 1rem 1.5rem 1rem;
}

.dropbtn-sort {
  border-radius: 4px 4px 0 0;
  width: 12rem;
  border: 0;
  padding: 0.3rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.building > .dropdown-sort > .dropdown-content-sort {
  background-color: #fff;
}

.building > .dropdown-sort > .dropbtn-sort {
  background-color: #fff;
  box-shadow: 2px 0 35px 0 rgba(154, 161, 171, 0.15);
}

.dropdown-sort-sidebar > .dropbtn-sort {
  width: -webkit-fill-available;
  background-color: #f7f7f7;
}

.dropbtn-sort:focus {
  outline: none;
}

.dropdown-sort-sidebar > .dropdown-content-sort {
  width: -webkit-fill-available;
  background-color: #f7f7f7;
}

.dropdown-building-button-sort {
  color: #000;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: self-end;
}

.dropdown-content-sort {
  display: none;
  padding: 4px 6px;
  width: 12rem;
  border-radius: 0 0 4px 4px;
  border: 0;
  background-color: #efefef;
  z-index: 1;
  box-sizing: border-box;
}

.dropdown-row-sort {
  cursor: pointer;
  margin: 0.5rem 0.2rem;
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
}

.apply-zone-settings-modal .modal-title {
  color: #383948;
  font-family: "Avenir", sans-serif;
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.apply-zone-settings-modal .modal-body p {
  color: #212529;
  font-size: 14px;
  font-weight: 500;
}

.btn-close {
  background-color: #fff;
  background-image: url("../Img/icons/close.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  border: 0;
  min-height: 1.5rem;
  min-width: 1.5rem;
  padding: 0;
  align-self: center;
  margin-top: -0.2rem;
}
